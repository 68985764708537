import React from 'react';
import Footer from './Footer';
import gal1 from '../img/gal (1).jpeg';
import gal2 from '../img/gal (2).jpg';
import gal3 from '../img/gal (3).jpg';
import gal4 from '../img/gal (4).jpg';
import gal5 from '../img/gal (5).jpg';
import gal6 from '../img/gal (6).jpg';

const Photoes = () => {
  const images = [gal1, gal2, gal3, gal4, gal5, gal6];

  return (
    <>
      <div className='headings'>
        <h1>Photos</h1>
      </div>

      <div className='gallery'>
        {images.map((img, index) => (
          <img key={index} src={img} alt={`Gallery ${index + 1}`} className='gallery-img' />
        ))}
      </div>

      <br /> <br />
      <Footer />
    </>
  );
}

export default Photoes;
