import React from 'react'
import Footer from '../component/Footer'
import related from '../img/relatedbreast.jpg' // Importing breast-related image

const BreastRelated = () => {
    return (
        <>
            <div className='headings'>
                <h1>Breast Related Problems</h1>
            </div>

            <div className='breast-related-content'>
                <p>
                    Breast-related problems can vary from benign conditions such as cysts, mastitis, and fibroadenomas, to more serious 
                    issues like breast cancer. Common symptoms may include lumps, pain, nipple discharge, or skin changes. Early detection 
                    of any abnormalities is essential, which is why regular self-exams and screenings are recommended for individuals at 
                    risk. Treatment for breast-related problems depends on the diagnosis and may involve medication, surgery, or other 
                    interventions. Consult your healthcare provider if you experience any unusual symptoms.
                </p>

                <img 
                    src={related} 
                    alt="Breast Related Problem Awareness" 
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }} 
                />
            </div>

            <Footer />
        </>
    )
}

export default BreastRelated
