import logo from './logo.svg';
import './App.css';
import './Treatments/treat.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './component/Layout';
import Navbar from './component/Navbar';
import About from './component/About';
import Contact from './component/Contact';
import Photoes from './component/Photoes';
import Videos from './component/Videos';
import Thyroid from './Treatments/Thyroid';
import DiabeticFoot from './Treatments/DiabeticFoot';
import Parathyroid from './Treatments/Parathyroid';
import AdrenalTumours from './Treatments/AdrenalTumours';
import Endocrine from './Treatments/Endocrine';
import BreastCancer from './Treatments/BreastCancer';
import BreastRelated from './Treatments/BreastRelated';
import Mehrotra from './component/Mehrotra';
import Myabout from './Treatments/Myabout';
import VisitorCounter from './component/VisitorCounter';
import Cancer from './Treatments/Cancer';

function App() {
  return (
    <div className='main'>
      <Router>
        <Mehrotra />
        <Navbar />
        <Routes>
          <Route path="*" element={<Layout />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Photoes" element={<Photoes />} />
          <Route path="/Videos" element={<Videos />} />
          <Route path="/Thyroid" element={<Thyroid />} />
          <Route path="/DiabeticFoot" element={<DiabeticFoot />} />
          <Route path="/Parathyroid" element={<Parathyroid />} />
          <Route path="/AdrenalTumours" element={<AdrenalTumours />} />
          <Route path="/Endocrine" element={<Endocrine />} />
          <Route path="/BreastCancer" element={<BreastCancer />} />
          <Route path="/BreastRelated" element={<BreastRelated />} />
          <Route path="/Myabout" element={<Myabout />} />
          <Route path="/Cancer" element={<Cancer />} />
          <Route path="/VisitorCounter" element={<VisitorCounter />} />

        </Routes>
      </Router>

    </div>
  );
}

export default App;


