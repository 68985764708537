import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from '../img/rewscan.jpg'; 

const reviews = [
    {
        id: 1,
        text: "Dr. Prateek Mehrotra is a fantastic endocrine surgeon. From my first consultation, I was impressed with his warm welcome and thorough explanation of the options available. He is knowledgeable, skilled, patient, and understanding.",
        author: "Nidhi Singh",
        rating: 5,
    },
    {
        id: 2,
        text: "I visited Dr. Prateek Mehrotra for my mother’s breast lump surgery. He is an extremely talented and humble doctor who listens patiently and explains all possible options in a layman manner.",
        author: "Apurva Gupta",
        rating: 5,
    },
    {
        id: 3,
        text: "If you need expert solutions for issues related to Dr. Prateek or dental issues, you must visit the clinic. He is humble and guides you until the last step of treatment.",
        author: "Akhilesh Pratap Singh",
        rating: 5,
    },
    {
        id: 4,
        text: "Dr. Prateek Mehrotra is a very experienced endocrine and breast surgeon in Lucknow. I am satisfied with my consultation, and my recent surgery was successful.",
        author: "Pradeep Rajpoot",
        rating: 5,
    },
    {
        id: 5,
        text: "I have found Dr. Prateek to be ultimate in his skill and approach. He takes personal interest in every case and is absolutely down to earth.",
        author: "Satish Chanda Sawhney",
        rating: 5,
    },
    {
        id: 6,
        text: "If you are looking for the best breast surgeon in Lucknow, your search stops at Dr. Prateek. He possesses excellent skills and a kind heart.",
        author: "Veena Sawhney",
        rating: 5,
    },
];

const ReviewsSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className='reviews-section'>
            <img src={logo} alt='Company Logo' className='logo' />
            <h1 className='gradient-text'>What our Patients say</h1>
            <Slider {...settings}>
                {reviews.map(review => (
                    <div key={review.id} className='review-card'>
                        <p className='review-text'>{review.text}</p>
                        <h4 className='review-author'>- {review.author}</h4>
                        <p className='review-rating'>Rating: {review.rating} ⭐</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ReviewsSlider;
