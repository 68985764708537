import React from 'react';
import '../css/about.css'
import Footer from './Footer';

const About = () => {
    return (

        <>


            <div className='headings'>
                <h1>About Us</h1>
            </div>


            <div className="about-container">

               

                <h2>FOCUS OF WORK</h2>
                <p>
                    •	Benign Thyroid Diseases- Multinodular & Retrosternal goiters, Graves’ diseases, Toxic goiters, Autonomous functioning thyroid nodules, Thyroglossal remnants.
                </p>
                <p>•	Malignant Thyroid Diseases- Papillary, Follicular, Medullary and Anaplastic thyroid cancers,  Lymphomas. Surgery for advanced thyroid cancers- Tracheal resection, Great vein thrombectomy, Thyroidectomy and neck dissections. </p>

                <p>•	Diabetic Foot Surgery:- Comprehensive diabetic foot care, running a diabetic foot clinic.</p>
                <p>•	Breast Diseases- Breast cancers & Benign breast lesions. Breast conserving surgery, Sentinel lymph node biopsy, Radical and Modified radical mastectomy, Skin sparing mastectomy, Lumpectomy, Microdochectomy. Chemotherapy in breast malignancies in adjuvant, neo-adjuvant and metastatic setting. Chemotherapy port placements</p>
                <p>•	Parathyroid Diseases- Hyperparathyroidism- sporadic and in MEN settings. Minimally invasive parathyroidectomy, Radio-guided surgeries.</p>
                <p>•	Adrenal Diseases- Open and laparoscopic procedures for Pheochromocytoma, Cushing’s syndrome, Hyperaldosteronism, adenomas and adreno-cortical carcinomas.</p>
                <p>•	Neuroendocrine Pancreatic & GIT Diseases- Carcinoid tumours, Insulinoma, Glucagonoma, Somatostatinomas, VIPoma, PPoma’s</p>




                <h2>ASSIGNMENTS (TILL DATE)</h2>


                <p>Associate Director, Department of Breast & Endocrine Surgery, Max Super-Specialty Hospital (Erstwhile Sahara Hospital), Lucknow, India.</p>

                <p>Senior Consultant, Department of Breast & Endocrine Surgery, Sahara Multi-Specialty Hospital, Lucknow, India.</p>

                <p>M.Ch fellow in Endocrine & Breast Surgery, Dept of Endocrine & Breast Surgery, Sanjay Gandhi Post-graduate Institute of Medical Sciences (SGPGIMS), Lucknow, India.</p>

                <p>Senior Resident in Endocrine & Breast Surgery (March 06-June 07), Dept of Endocrine & Breast Surgery, Sanjay Gandhi Post-graduate Institute of Medical Sciences (SGPGIMS), Lucknow, India.</p>

                <p>Senior Resident in General Surgery, Deen Dayal Upadhyay Hospital, Hari Nagar, New Delhi, India.</p>

                <p>Senior Resident in General Surgery, City Hospital, Affiliated to Sir Ganga Ram Hospital, New Delhi, India.</p>

                <p>Junior Resident in General Surgery, Sir Ganga Ram Hospital, New Delhi, India.</p>
                <p>•	Gold Medal in Forensic Medicine and Honors in Biochemistry</p>










                <h3>AWARDS / FELLOWSHIPS / GRANTS</h3>
                <p>International Society of Surgery Foundation (ISSF) Lupsingen, Switzerland. Travel Scholar Award 2011. Awarded at International Surgical Week ISW Yokohama, Japan. 2011.</p>

                <p>“Mumbai-Asia Pacificon Traveling Fellowship” for 2009-2010. Awarded by the International College of Surgeons-Indian Section.</p>

                <p>“SGPGI Best M.Ch Senior Resident Award” Awarded by Sanjay Gandhi Post Graduate Institute of Medical Sciences. 2009.</p>

                <p>“IAES Travel Grant”. Awarded by the International Association of Endocrine Surgeons, Adelaide, Australia. 2009.</p>

                <p>“Japan Surgical Society Travel Grant” for the 109th Japan Surgical Society Conference at Fukuoka, Japan. April 2009.</p>

                <p>“Dr. S. Vital Best Paper Award” in Endocrine Surgery sectional program during ASICON 2008 at Ludhiana, 26-30th Dec 2008. “Childhood and adolescent Pheochromocytoma: An institutional experience over two decades.”</p>

                <p>“Hari Om Ashram Prerit Dr. Rangachari Research Endowment Award 2006.” Awarded by Association of Surgeons of India (ASI) for “Breast Cancer and persistent organochlorine pesticides exposure among Indian women: A possible association.”</p>

                <p>“N.K Mehra Award, 2003” for the best postgraduate student in General Surgery, Sir Ganga Ram Hospital, New Delhi, during DNB training (2001-04).</p>

                <p>“Shri Badri Prasad Tiwari Gold Medal, 1997” for highest marks in Forensic Medicine in MBBS.</p>

                <p>“Dr. Harish Gupta Prize 1995” - Association of Physiologists & Pharmacologists of India (APPI) for the best undergraduate paper presentation at the Annual Conference of APPI-1995. "Study of Pulmonary Functions in Swimmers of Lucknow City".</p>


                <h2>MEMBERSHIPS</h2>
                <p>FAES- Fellow of Indian Association of Endocrine Surgeons of India (2024)</p>

                <p>ITS- Indian Thyroid Society (2024)</p>

                <p>ABSI- Association of Breast Surgeons of India</p>

                <p>ISTS- Indian Society of Thyroid Surgeons (2019)</p>

                <p>DFSI- Diabetic Foot Society of India (2015)</p>

                <p>ISBMR- Indian Society of Bone & Mineral Research (2011)</p>

                <p>FLCS- Fellow of Lucknow College of Surgeons (2010)</p>

                <p>IMA- Indian Medical Association- Lucknow Branch</p>

                <p>IAES- Indian Association of Endocrine Surgeons</p>

                <p>AMASI- Association of Minimal Access Surgeons of India (2007)</p>

                <p>IASO- Indian Association of Surgical Oncology (2008)</p>

                <p>RCS.Ed- Royal College of Surgeons of Edinburgh (2005)</p>

                <p>IMSA- International Medical Sciences Academy (2006)</p>

                <p>NAMS- National Academy of Medical Sciences, India (2005)</p>

                <p>ASI- Association of Surgeons of India (2002)</p>

                <p>APPI- Association of Physiologists & Pharmacologists of India (1998)</p>





                <h2>PUBLICATIONS IN INDIAN JOURNALS</h2>
                <p>Mehrotra PK, Ramchandran CS, Goel D, Arora V. “Inflammatory variant of a well-differentiated retroperitoneal liposarcoma: case report of a rare giant variety.” Ind. J Cancer. 43(1), 36-38 Mar 2006.</p>

                <p>Mehrotra PK, Verma NS, Tiwari S, Kumar P. “Pulmonary functions in Indian sportsmen playing different sports” Ind. J Physiology Pharmacology 42(3), 412-416: July 1998.</p>

                <p>Mehrotra PK, Yadav RK, Verma NS, Tiwari S, Shukla N "Study of pulmonary functions in swimmers of Lucknow city". Ind. J Physiology Pharmacology. 41(1), 83 - 86: Jan 1997.</p>


                <h2>PUBLICATIONS IN INTERNATIONAL JOURNALS</h2>
                <p>Shukla A, Singh P, Shukla A, Mehrotra PK, Arshad F, Jain P. Study of correlation of ER, PR, Her2neu receptor status in breast cancer at a single tertiary care hospital with emphasis on clinical utility of PR receptor. International Journal of Community Medicine & Public Health. 2018 Nov; 5(11): 4853-4857.</p>

                <p>Mishra A, Mehrotra PK, Agarwal G, Agarwal A, Mishra SK. Pediatric and adolescent pheochromocytoma: clinical presentation and outcome of surgery. Indian Pediatr. 2014 Apr;51(4): 299-302.</p>

                <p>Anand M, Singh J, Siddiqui MKJ, Taneja A, Patel DK, Mehrotra PK. Organo-chlorine pesticides in the females suffering from Breast Cancer and its Relation to Estrogen Receptor Status. Drug Metab Toxicol April 2013 4:(4). JMDT, an open access journal.</p>

                <p>Mehrotra PK, Mishra A, Mishra SK, Agarwal G, Agarwal A, Verma AK. Medullary Thyroid Cancer: Clinico-pathological Profile and Outcome in a Tertiary Care Center in North India. World J Surg. 2011 Jun; 35(6); 1273 - 80.</p>

                <p>Mishra A, Chandra R, Mehrotra PK, Bajpai P, Agrawal D. Glutathione S-transferase M1 and T1 polymorphism and response to neoadjuvant chemotherapy (CAF) in breast cancer patients. Surg Today. 2011 Apr; 41(4): 471-6. Epub 2011 Mar.</p>

                <p>Mehrotra PK, Ramachandran CS, Arora V. Two port laparoscopic ventral hernia mesh repair: an innovative technical advancement. Int J Surg. 2011; 9(1): 79-82. Epub 2010 Oct 8.</p>

                <p>Agarwal A*, Mehrotra PK*, Jain M, Gupta SK, Mishra A, Chand G, Agarwal G, Verma AK, Mishra SK, Singh U. Size of the Tumor and Pheochromocytoma of the Adrenal Gland Scaled Score (PASS): Can They Predict Malignancy? World J Surg. 2010 Dec; 34(12): 3022-8.</p>

                <p>Mehrotra PK, A Mishra. Response to External beam radiotherapy in patients with advanced or recurrent non anaplastic thyroid cancer: Memorial Sloan Kettering cancer center experience. Int J Radiat Oncol Biol Phys. 2010 Jan 1; 76(1):314; author reply 314.</p>

                <p>M Ahamed, PK Mehrotra, P. Kumar, MKJ Siddiqui. “Placental Lead Induced oxidative stress and preterm delivery” Environmental Toxicology and Pharmacology. 2009. 27(1): 70-74.</p>

                <p>NK Kumar, PK Mehrotra, A Agarwal, AK Verma. “Hypocalcaemia & parathyroid hormone assay following total thyroidectomy: predicting the future. World J Surg. 2007 Aug; 31(8):1718-9.</p>

                <p>PK Mehrotra, A Agarwal, A Mishra, G Agarwal, AK Verma, SK Mishra. “Comments on: Technetium 99m-sestamibi subtraction scintigraphy vs. ultrasonography combined with a rapid parathyroid hormone assay in parathyroid aspirates in preoperative localization of parathyroid adenoma and in directing surgical approach” Clin Endocrinol 2007. 66: 899-900.</p>

                <p>Siddiqui MK, Jyoti, Singh S, Mehrotra PK, Singh K, Sarangi R. “Comparison of some trace elements concentration in blood, tumour free breast and tumour tissues of women with benign and malignant breast lesion: An India study.” Environ Int. 2006 Jul; 32(5): 630-7.</p>

                <p>Mehrotra PK, Ramchandran CS, Gupta L. “Gallstone presenting as obstructive gangrenous appendicitis: Laparoscopic management” Journal of Laparo-endoscopic and Advanced Surgical Techniques. 2005 Dec; 15(6): 627-9.</p>

                <p>Mehrotra PK, Ramchandran CS, Goel D, Arora V. “Giant pseudocyst of the anterior abdominal wall following mesh repair of incisional hernia: a rare complication managed laparoscopically.” Hernia. 2005 Sep 1; 1-3.</p>

                <p>Siddiqui MKJ, Anand M, Mehrotra PK, Sarangi R, Mathur N. “Biomonitoring of organo-chlorines in women with benign and malignant breast disease”. J of Environmental Research. 2005 Jun; 98(2): 250-7.</p>

                <p>Siddiqui MKJ, Srivastava S, Srivastava SP, Mehrotra PK, Mathur N, Tandon I. "Persistent chlorinated pesticides and intra-uterine foetal growth retardation: a possible association”. Archives of Occupational & Environmental Health 2003. (76): 75-80.</p>

                <p>Siddiqui MKJ, Mehrotra PK, Srivastava S. “Environmental exposure of lead as a risk for prostate cancer” Biomedical & Environmental Science. 2002 Dec 15 (4): 298 - 305.</p>

                <p>Srivastava S, Mehrotra PK, Srivastava SP, Siddiqui MKJ. “Some essential elements in maternal and cord blood in relation to birth weight and gestation age of the baby” Journal of Biological Trace Element Research. 2002 May: 86 (2): 97 - 105.</p>

                <p>Srivastava S, Mehrotra PK, Srivastava SP, Tandon I, Siddiqui MKJ. “Blood lead and zinc in pregnant women and their offspring in Intra Uterine Growth retarded cases.” Journal of Analytical Toxicology 2001 Sept 25 (6): 461-465.</p>


                <h2>BOOK CHAPTERS / CONTRIBUTION IN PROCEEDINGS</h2>
                <p>1.	“Pancreatic Neuro-endocrine Tumours.” SK Mishra, Prateek K Mehrotra. Text Book Chapter for the 2nd Edition of the “ASI Text Book of Surgery”</p>
                <p>2.	“What’s new in management of Goiter” Prateek Mehrotra, S.K Mishra. (Chapter in Proceedings) 53rd Foundation day and 5th Surgical Education Program. Department of Surgery. CSM Medical University Lucknow. 16th-17th Jan 2008.</p>


                <h2>PAPERS PRESENTED AT CONFERENCES  </h2>
                <p>1. “Hypocalcemia following Thyroidectomy” & Panel Discussion on Complications of Thyroidectomy. 25th Indian Association of Endocrine Surgeons of India. (IAESCON 2024) Lucknow 19-21st Sept 2024.</p>
                <p>2. “Panel Discussion on Spectrum of Benign Breast Diseases” in 12th Annual Conference of Association of Breast Surgeons of India (ABSICON 2024). Lucknow 2nd–4th August 2024.</p>
                <p>3. “Antimicrobial prophylaxis in different organ system surgeries” State CME on anti-microbial stewardship. Organized by Lucknow chapter of Hospital Infection Society, India. 9th March 2019.</p>
                <p>4. “Lingual Thyroid” 4th Annual meeting of Indian Society of Thyroid Surgeons (ISTS). SGPGI Lucknow. 15 – 17 Feb 2019.</p>
                <p>5. “Metaplastic Squamous cell carcinoma of the Breast: Does it really have a poor outcome? Review of two cases.” World Congress of Surgery (WCS-2017). The International Society of Surgery ISS/SIC. Basel Switzerland 13 - 17 Aug 2017.</p>
                <p>6. “Micro papillary carcinoma Thyroid- To Observe or to Intervene (Debate)”. 17th Annual Conference of Indian Association of Endocrine Surgeons. 17th – 18th Sept 2016 Subharti Medical College, Meerut.</p>
                <p>7. “Approach to management of Complications of Thyroidectomy: Panel discussion” 75th Annual Conference of the Association of Surgeons of India. ASICON 2015. Gurgaon. 16th - 20th Dec 2015.</p>
                <p>8. “Primary Hyperparathyroidism: diagnosis & Localization”. 12th Post Graduate course in Endocrine Surgery (PGES) SGPGI, Lucknow. 29th Oct – 1st Nov 2015.</p>
                <p>9. “Isolated tubercular lesions of the breast” World Congress of Surgery (WCS-2015). The International Society of Surgery ISS/SIC. Bangkok. Thailand 25-29 Aug 2015.</p>
                <p>10. “Micro-papillary thyroid Carcinoma: An update” 73rd Annual Conference of the Association of Surgeons of India. ASICON 2013. Ahmedabad. 24th to 30th Dec 2016.</p>
                <p>11. “Idiopathic Granulomatous Mastitis: a great imitator!” Mehrotra PK, Arshad F, Chaturvedi A, Bhalla S, Bhattacharya N. The International Society of Surgery ISS/SIC. International Surgical Week (ISW 2013). Helsinki, Finland. 25-29 Aug 2013.</p>
                <p>12. “Primary hyperparathyroidism (PHPT): Factors influencing the need for intravenous (IV) calcium infusion following parathyroidectomy.” Prateek K. Mehrotra, SK Mishra, A Mishra, G Chand, G Agarwal, A Agarwal, AK Verma Annual conference of Asian Association of Endocrine Surgeons (AAES) Tokyo. Japan March 2010.</p>
                <p>13. “Focused Parathyroidectomy for Giant Benign Parathyroid adenomas.” A Agarwal, SK Gupta, PK Mehrotra, G Chand, A Mishra, G Agarwal, AK Verma, SK Mishra. Annual conference of Asian Association of Endocrine Surgeons (AAES) Tokyo. Japan March 2010.</p>
                <p>14. “An institutional experience over two decades of childhood and adolescent pheochromocytoma.” Prateek K. Mehrotra, G. Chand, A. Mishra, G.Agarwal, A.Agarwal, AK Verma, SK Mishra. 43rd World Congress of the International Surgical Society (ISS) to be held at Adelaide, Australia. 5th – 10th September 2009.</p>
                <p>15. “Outcome of compartment oriented Surgery in Palpable Medullary Thyroid Cancer” Prateek K. Mehrotra, SK Mishra, A Mishra, G Chand, G Agarwal, A Agarwal, AK Verma 109th Annual Congress of the Japan Surgical Society (JSS). Fukuoka, Japan. 2nd-4th April 2009.</p>
                <p>16. “Outcomes of Compartment Oriented Surgery in Locally advanced Medullary Thyroid cancer” Amit Agarwal, Prateek K Mehrotra. G Chand, A Mishra, M Jain, G Agarwal, AK Verma, SK Mishra. World Congress on Thyroid Cancer. Toronto Canada. 6-10th Aug 2009.</p>
                <p>17. “Childhood and adolescent Pheochromocytoma: An institutional experience over two decades.” Prateek K. Mehrotra, G. Chand, A.Mishra, G.Agarwal, A.Agarwal, AK Verma, SK Mishra. 68th Annual Conference of the Association of Surgeons of India. ASICON 2008 Ludhiana. Punjab. Dec 25th-30th 2008.</p>
                <p>18. “Risk of Malignancy in large (6 cm) pheochromocytoma: Is it overstated in literature” Agarwal A, Mehrotra PK, Mishra A, Verma AK, SK Mishra, Agarwal G, Gupta SK. 42nd World congress of International Surgical Society. 26-30th Aug 2007 Montreal, Canada (Poster).</p>
                <p>20. “Case presentation: Papillary Thyroid cancer diagnosed as adenoid cystic carcinoma on FNAC.” Prateek K Mehrotra, Sushila Jaiswal. CME on Endocrine Pathology. Department of Pathology. SGPGIMS Lucknow. 15th Sept 07.</p>
                <p>21. “Breast cancer and persistent organo-chlorine pesticide exposure among India women: A possible association.” Prateek K Mehrotra. 66th Annual conference of association of Surgeons of India ASICON 2006 BHU, Varanasi. Dec 2006.</p>
                <p>“Medullary thyroid cancer: a 15 year institutional experience” Prateek K Mehrotra, A Agarwal, AK Mishra, A Mishra, M Jain, G Agarwal, AK Verma, SK Mishra. Presented at the 66th Annual conference of Association of Surgeons of India (ASICON) 2006 at BHU, Varanasi. Dec 2006.</p>
                <p>“Environmental exposure to organo-chlorine pesticides and risk of breast cancer: Indian Scenario” Anand. M, Jyoti, Mehrotra Prateek K, Siddiqui MKJ. International conference on Toxicology, toxicogenomics and occupational health (ICTTOH) & 26th annual meeting of society of toxicology (STOX) 9-11 Oct 2006. Gwalior. India.</p>
                <p>“Are certain metals associated with breast lesions?” Jyoti, Anand. M, Mehrotra. Prateek K, Siddiqui MKJ. International conference on Toxicology, toxicogenomics and occupational health (ICTTOH) & 26th annual meeting of society of toxicology (STOX) 9-11 Oct 2006. Gwalior. India.</p>
                <p>“Enhanced antioxidant enzyme activity and lipid peroxidation in placental tissue of women with pre-term deliveries.” M Ahmad, Prateek K Mehrotra, P Kumar, MKJ Siddiqui. (Abstract PP 40, pg 70). XXIX All India Cell Biology conference & Symposium on Gene to Genome: Environment & Chemical Interaction. Jan 17-20, 2006 at ITRC, Lucknow India.</p>
                <p>26. “Laparoscopic reversal of Hartmans procedure” CS Ramchandran, PK Mehrotra, D, Goel, V. Arora. (Abstract No V035) 13th International Congress of European Association for Endoscopic Surgery (EAES) 1-4 June 2005, Venice, Italy.</p>
                <p>27. Trans-anal endo-rectal pull through for Hirschprungs' disease. Prateek K Mehrotra, K Vidyarthi. Presented at the 29th annual conference of Association of Pediatric Surgeons of India (IAPS). Hyderabad India. Nov. 2003.</p>
                <p>28. MAGPI re-visited. Prateek K Mehrotra, K Vidyarthi. Presented at 29th annual conference of Association of Pediatric Surgeons of India (IAPS). Hyderabad India. Nov 2003.</p>
                <p>29. "Laparoscopic management of acute abdomen" Prateek K Mehrotra, R. Sarangi. Presented at the XII National Conference of Surgical Gastroenterology at Hyderabad, India September 2002.</p>
                <p>30. "Extraperitoneal endoscopic repair of groin hernia: A long term follow up study" Mehrotra PK, Jindal P, Taneja L, Sarangi R. Presented at the 5th Annual Conference of Indian association of Gastrointestinal Endo-Surgeons (IAGES) in Kolkotta, India. Feb 2002.</p>
                <p>31. "Extraperitoneal endoscopic repair of irreducible groin hernia: a study of its feasibility and advantages" Prateek K Mehrotra, P Jindal, L Taneja, R Sarangi. Presented at the 5th Annual Conference of Indian Association of Gastrointestinal Endo-Surgeons (IAGES) in Kolkotta, India. Feb 2002.</p>
                <p>32. "Endoscopic management of complex colonic obstruction" Prateek K Mehrotra, R Sarangi, S Joshi. Presented at the 5th Annual Conference of Indian Association of Gastrointestinal Endo-Surgeons (IAGES) in Kolkotta, India. Feb 2002.</p>
                <p>33. "Extra-peritoneal Endoscopic Repair of a relatively big bilateral direct inguinal hernia." Jindal Pramoj, Mehrotra PK, Taneja L, Singh K, Sarangi R. Presented at the 5th Annual Conference of Indian Association of Gastrointestinal Endo-Surgeons (IAGES) in Kolkotta, India. Feb 2002.</p>
                <p>34. “Effect of acute exercise on blood pressure and blood catecholamine levels in normotensive offspring’s of hypertensive parents.” Mehrotra PK, Verma NS, Tiwari S, Kumar P. Abstract Indian Journal Physiology Pharmacology. Vol. 42 No. 5 Supplement 1998 Pg. 596A.</p>
                <p>35. “Acute sporadic non A to G hepatitis” Mehrotra Raj, Mehrotra PK. Abstract- Ind. J Gastroenterology Vol. 17 No. 2: April 1998 Pg. S3.</p>
                <p>36. “Effect of voluntary and involuntary exercises on some lung functions” Mehrotra PK, Singh R, Tandon HC, Agarwal JL, Verma P, Abstract Ind. J Physiology Pharmacology 41(5) Suppl. 1997 Pg. 446A. Presented at 43rd Annual conference of APPI. Lucknow. Dec 1997.</p>
                <p>37. “Comparative study on serum lactate, inorganic phosphate & oxygen debt in voluntary and electrically induced muscular exercise” Singh R, Tandon HC, Agarwal JL, Verma P, Bhushan S. Mehrotra PK, Abstract- Indian J Physiology Pharmacology Vol. 41 (5) Suppl. 1997 Pg. 454-A. Presented at 43rd Annual conference of APPI. Lucknow, India. Dec 1997.</p>
                <p>38. “Oral white lesions and candidiasis- Incidence and correlation” Mehrotra PK, Kapoor S, Mishra S, Singh SN, Srivastava AN. Presented at VIth National Conference of ‘Indian Academy of Oral Pathologists (IAOP)’. Lucknow. Nov 97. Studentship awarded by Indian Council of Medical Research (ICMR) for 1997.</p>
                <p>39. “Auditory and visual reaction time in sports persons" Mehrotra PK, Verma NS, Tiwari S, Kumar Prabhat. IInd Congress of Federation of Indian Physiological Societies (FIPS). Tirupati, India. Sept 1997. (Poster presentation)</p>
                <p>40. “Pulmonary functions in Indian sportsmen playing different sports” 42nd Annual Conference of Association of Physiologists & Pharmacologists of India (APPI). S.C.B Medical College, Cuttuck. 25-28th Dec 1996.</p>
                <p>41. "Study of Pulmonary Functions in Swimmers of Lucknow City".- 41st Annual Conference of Association of Physiologists & Pharmacologists of India (APPI) Jawahar Lal Nehru Medical College, Belgaum. 26-28-1995.</p>
                <p>42. “Comparative study of hemoglobin levels in two different socio-economic strata.” Mehrotra PK, Verma NS, Tiwari S, Shukla N. --presented at First Congress of Federation of Indian Physiological Societies (FIPS)- New Delhi. India. March 1995.</p>

                <h2>	ALLIED TRAINING IN ENDOCRINE SURGERY	 	</h2>

                <p>1. Nagoya University Hospital & allied hospitals. Nagoya, Japan.</p>
                <p>2. Royal North Shore Hospital. Sydney, Australia.</p>
                <p>3. Diabetic Foot Ulcer Management course. Amrita Institute of Medical Sciences, Kochi, Kerala.</p>

            </div>

            <hr />

            <Footer />
        </>
    );
};

export default About;
