import React, { useEffect, useState } from 'react';
import { database, ref, get, update } from './firebase';

function VisitorCounter() {
    const [totalVisits, setTotalVisits] = useState(0);
    const [todayVisits, setTodayVisits] = useState(0);
    const [monthVisits, setMonthVisits] = useState(0);
    const [yearVisits, setYearVisits] = useState(0);

    useEffect(() => {
        const visitRef = ref(database, 'visitData');
        const now = new Date();
        const today = now.toDateString();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
        const monthYear = `${currentMonth}-${currentYear}`;

        get(visitRef).then(snapshot => {
            const data = snapshot.val() || {
                total: 0,
                todayCount: 0,
                monthCount: 0,
                yearCount: 0,
                lastVisitDate: null,
                monthYear: null,
                year: null,
            };

            // Update total visits
            data.total += 1;

            // Check if today is a new day
            if (data.lastVisitDate !== today) {
                data.todayCount = 1;
                data.lastVisitDate = today;
            } else {
                data.todayCount += 1;
            }

            // Check if a new month has started
            if (data.monthYear !== monthYear) {
                data.monthCount = 1;
                data.monthYear = monthYear;
            } else {
                data.monthCount += 1;
            }

            // Check if a new year has started
            if (data.year !== currentYear) {
                data.yearCount = 1;
                data.year = currentYear;
            } else {
                data.yearCount += 1;
            }

            // Save updated data to Firebase
            update(visitRef, data);

            // Update state
            setTotalVisits(data.total);
            setTodayVisits(data.todayCount);
            setMonthVisits(data.monthCount);
            setYearVisits(data.yearCount);
        });
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f5f5f5' }}>
            <div className="visit-counter" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', width: '50%' }}>
                <div className="counter-item">
                    <strong>Total Visits:</strong> {totalVisits}
                </div>
                <div className="counter-item">
                    <strong>Today's Visits:</strong> {todayVisits}
                </div>
            </div>
        </div>
    );
}

export default VisitorCounter;
