import React from 'react'
import Footer from './Footer'

const Videos = () => {
  return (
    <>

      <div className='headings'>
        <h1>Videos</h1>
      </div>



      <br />
      <Footer />

    </>
  )
}

export default Videos
