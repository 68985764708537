import React from 'react';
import Footer from '../component/Footer';
import diabetic from '../img/diabeticfoot.jpg';
import foot1 from '../img/pdf (1).jpg';
import foot2 from '../img/pdf (2).jpg';

const DiabeticFoot = () => {
    return (
        <>
            <div className="headings">
                <h1>Understanding Diabetic Foot Diseases: Awareness and Prevention</h1>
            </div>

            <div className="diabetic-foot-content">
                <p>
                    Diabetic foot disease (DFD) is a significant complication of diabetes mellitus, affecting millions globally. 
                    It encompasses a range of foot problems that arise due to the combination of neuropathy, ischemia, and infection, 
                    leading to tissue breakdown and, in severe cases, amputation. This article aims to raise awareness about diabetic foot 
                    diseases, their causes, preventive measures, and the importance of education in managing diabetes effectively.
                </p>

                <h2>What is Diabetic Foot Disease?</h2>
                <p>
                    Diabetic foot disease refers to a group of syndromes that can lead to foot ulcers, infections, and ultimately amputations 
                    if not managed properly. Studies indicate that up to 15% of diabetic patients will develop foot ulcers in their lifetime, 
                    and about 85% of amputations in diabetic patients are preceded by foot ulcers.
                </p>

                <img src={diabetic} alt="Diabetic Foot Overview" />

                <h2>Causes and Risk Factors</h2>
                <p>The primary causes of diabetic foot problems include:</p>
                <ul>
                    <li><strong>Peripheral Neuropathy:</strong> Damage to the nerves can result in loss of sensation in the feet, making it difficult for patients to notice injuries or infections.</li>
                    <li><strong>Peripheral Artery Disease (PAD):</strong> Reduced blood flow can impair healing and increase the risk of infections.</li>
                    <li><strong>Infections:</strong> Diabetics are more susceptible to infections due to compromised immune responses.</li>
                    <li><strong>Foot Deformities:</strong> Conditions like Charcot foot can alter the structure of the foot, increasing pressure points.</li>
                </ul>
                <img src={foot1} alt="Causes of Diabetic Foot" />

                <p>Risk factors for developing DFD include poor glycemic control, history of foot ulcers or amputations, smoking, and lack of regular foot care practices.</p>

                <h2>Importance of Awareness and Education</h2>
                <p>
                    Despite the serious nature of DFD, awareness among diabetic patients remains low. Studies show that many patients lack knowledge about proper foot care practices.
                </p>
                <ul>
                    <li>Approximately 64.5% of diabetic patients were unaware they need special attention for foot care.</li>
                    <li>A significant number do not regularly inspect their feet or wear appropriate footwear.</li>
                </ul>
                <p>
                    Education plays a crucial role in preventing diabetic foot complications. Regular check-ups and self-examinations can lead to early detection and treatment of potential issues.
                </p>

                <h2>Preventive Measures</h2>
                <p>To mitigate the risk of developing diabetic foot disease, individuals with diabetes should adopt the following practices:</p>
                <ul>
                    <li>Daily Foot Inspection: Check for cuts, blisters, redness, or swelling.</li>
                    <li>Proper Foot Hygiene: Wash feet daily with mild soap and water; dry thoroughly.</li>
                    <li>Moisturize: Use lotion on feet but avoid applying between toes.</li>
                    <li>Appropriate Footwear: Wear well-fitting shoes that provide support; avoid walking barefoot.</li>
                    <li>Regular Check-ups: Schedule regular visits with healthcare providers for foot assessments.</li>
                </ul>
                <img src={foot2} alt="Preventive Measures for Diabetic Foot" />

                <h2>Conclusion</h2>
                <p>
                    Diabetic foot disease is a preventable complication that requires increased awareness and proactive management among diabetic patients. 
                    By emphasizing education on proper foot care practices and encouraging regular medical check-ups, we can significantly reduce the incidence 
                    of DFD and improve the quality of life for individuals living with diabetes.
                </p>
            </div>

            <Footer />
        </>
    );
};

export default DiabeticFoot;
