import React from 'react';
import blog1 from '../img/blog1.jpg';
import blog2 from '../img/blog2.jpg';
import blog3 from '../img/blog3.jpg';
import blog4 from '../img/blog4.jpg';
import blog5 from '../img/blog5.jpg';
import blog6 from '../img/blog6.jpg';


const Blog = () => {
    const blogs = [blog1, blog2, blog3, blog4, blog5, blog6];

    return (
        <div className="blog-container">
            <h1 className="blog-title">Our Blogs</h1>
            <div className="blog-grid">
                {blogs.map((blog, index) => (
                    <div className="blog-card" key={index}>
                        <img src={blog} alt={`Blog ${index + 1}`} className="blog-image" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;
