import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "../css/carousal.css";  

import banner1 from '../img/banner1.jpg';
import banner3 from '../img/banner3.jpg';
import banner4 from '../img/banner4.jpg';
import banner5 from '../img/banner5.jpg';

const CustomCarousel = () => {
  return (
    <Carousel className="carousel-container">
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src={banner1}
          alt="First slide"
        />
      </Carousel.Item>

      
      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src={banner3}
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src={banner4}
          alt="Fourth slide"
        />
      </Carousel.Item>


      <Carousel.Item className="carousel-item">
        <img
          className="d-block w-100"
          src={banner5}
          alt="Fiveth slide"
        />
      </Carousel.Item>

     
    </Carousel>
  );
};

export default CustomCarousel;
