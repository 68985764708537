import React from 'react';
import Footer from '../component/Footer';
import foot from '../img/doctor.jpg';

const Parathyroid = () => {
    return (
        <>
           
            <div className="headings">
                <h1>Parathyroid & Metabolic Bone Diseases</h1>
            </div>

            <div className="parathyroid-content">
              
                <img 
                    src={foot} 
                    alt="Parathyroid illustration" 
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }} 
                />
            </div>

            {/* Detailed Information Section */}
            <div className="additional-info">
                <h2>Primary Hyperparathyroidism: The not so rare disease
                </h2>
                <p>
                    Primary hyperparathyroidism (PHPT) occurs when parathyroid glands produce excessive PTH, leading to hypercalcemia. 
                    PHPT presents in three clinical phenotypes: overt target organ involvement, mild asymptomatic hypercalcemia, and normo-calcemic hyperparathyroidism. 
                    PHPT may be present in familial settings like MEN type I & II, familial isolated HPT, and hyperparathyroidism-jaw tumor syndrome. 
                    The known offending genes are CCND1 and MEN1-menin gene. PHPT is caused by solitary adenomas (80-85%), four-gland hyperplasia (10–15%), 
                    multiple adenomas (5%), and parathyroid cancer (1%).
                </p>
                <h3>Symptoms</h3>
                <ul>
                    <li>Bone and joint pain</li>
                    <li>Muscle weakness</li>
                    <li>Fatigue and depression</li>
                    <li>Recurrent non-healing fractures or kidney stones</li>
                    <li>In severe cases: nausea, vomiting, abdominal pain, confusion, and shock</li>
                </ul>

                <h3>Diagnosis</h3>
                <p>
                    Diagnosis is biochemical. Asymptomatic HPT is often identified during routine calcium testing. Hypercalcemia, hypophosphatemia, and raised PTH levels are pathognomonic. 
                    PHPT can be distinguished from secondary and tertiary HPT by its biochemical profile. PTH levels should be measured with either an ‘intact’ second-generation PTH assay or a third-generation assay.
                </p>
                <p>
                    Parathyroid imaging assists in identifying the anatomic position of abnormal gland(s) when planning parathyroidectomy. Ultrasound has a high sensitivity for localizing parathyroid adenomas. 
                    Other imaging studies include Parathyroid 4-D CT, MRI, and radionuclide imaging with 99mTc-sestamibi or dual-isotope subtraction scans. 
                    Newer modalities like choline PET/CT are also available. Selective PTH venous sampling (PVS) is reserved for persistent or recurrent PHPT cases.
                </p>

                <h3>Treatment</h3>
                <p>
                    Surgery is the first-line treatment for PHPT and is recommended for all symptomatic patients. Asymptomatic patients who are 50 years; have calcium  1 mg/dL above normal; 
                    bone density T-score  -2.5 with increased risk of fractures, or recurrent kidney stones should also undergo surgery. Surgical approaches include focused parathyroidectomy, 
                    unilateral or bilateral neck exploration, and intraoperative PTH monitoring. Depending on the clinical setting, single gland removal, three and a half gland parathyroidectomy, 
                    or total parathyroidectomy with forearm auto-transplantation may be performed.
                </p>
                <p>
                    Parathyroidectomy is the definitive treatment for PHPT. When performed by experienced endocrine surgeons, the procedure has success rates exceeding 95% and a low rate of complications and recurrence.
                </p>
            </div>


            {/* Footer */}
            <Footer />
        </>
    );
};

export default Parathyroid;
