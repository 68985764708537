import React from 'react';
import Footer from '../component/Footer';
import breast from '../img/cancerbreast.jpg'; // Importing breast cancer image

const BreastCancer = () => {
    return (
        <>
            <div className="headings">
                <h1>Breast Cancer and Benign Breast Diseases</h1>
            </div>

            {/* Section for Benign Breast Disease */}
            <div className="breast-disease-content">
                <h2>Benign Breast Disease</h2>
                <p>Benign breast disease (BBD) encompasses a variety of non-cancerous conditions affecting breast tissue. While these conditions are generally not life-threatening, they can lead to symptoms such as pain, lumps, and discharge. Some types may increase the risk of developing breast cancer in the future.</p>

                <h3>Types of Benign Breast Disease</h3>
                <ul>
                    <li><b>Non-proliferative disorders:</b> These conditions do not increase the risk of breast cancer and include:
                        <ul>
                            <li>Fibrocystic changes: The most common type, often causing breast pain and lumpiness, particularly in women aged 20–50. Symptoms typically worsen before menstruation and improve afterward.</li>
                        </ul>
                    </li>
                    <li><b>Proliferative disorders without atypia:</b> These carry a mild to moderate increase in breast cancer risk. Examples include:
                        <ul>
                            <li>Fibroadenomas: Benign tumors that are common in younger women and usually do not require treatment unless symptomatic.</li>
                        </ul>
                    </li>
                    <li><b>Atypical hyperplasias:</b> These conditions present a substantial increase in cancer risk (relative risk of 4.1–5.3) and require careful monitoring.</li>
                </ul>

                <h3>Symptoms</h3>
                <ul>
                    <li><b>Breast pain (mastalgia):</b> Commonly associated with fibrocystic changes.</li>
                    <li><b>Nodularity:</b> A feeling of lumpiness in the breast tissue.</li>
                    <li><b>Nipple discharge:</b> Can occur with various benign conditions.</li>
                    <li><b>Palpable lumps:</b> Often detected during self-exams or clinical evaluations.</li>
                </ul>

                <h3>Diagnosis</h3>
                <p>Diagnosis typically involves a combination of physical examination, imaging techniques (such as mammography or ultrasound), and sometimes biopsy to confirm the nature of the lesions. Most benign lesions can be diagnosed with advancements in imaging technology.</p>

                <h3>Risk Factors</h3>
                <ul>
                    <li><b>Age:</b> Incidence increases from the second decade of life, peaking in the fourth and fifth decades.</li>
                    <li><b>Hormonal factors:</b> Conditions like early menarche, nulliparity, and hormone replacement therapy can elevate risks.</li>
                    <li><b>Family history:</b> A significant family history of breast cancer is associated with higher risks for both benign and malignant forms.</li>
                </ul>

                <h3>Management</h3>
                <p>Most benign breast diseases do not require treatment unless they cause significant discomfort or have atypical features that warrant closer monitoring. Management strategies may include:</p>
                <ul>
                    <li><b>Pain relief:</b> Medication to help decrease the sensitivity of fibro-glandular tissue along with assurance.</li>
                    <li><b>Supportive measures:</b> Wearing well-fitting bras and dietary modifications.</li>
                    <li><b>Surgical intervention:</b> Reserved for cases where there is significant pain, fibroadenomas, or lesions suspicious of malignancy.</li>
                </ul>
            </div>

            {/* Footer */}
            <Footer />
        </>
    );
};

export default BreastCancer;
