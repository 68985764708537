import React from 'react';

const Map = () => {
    return (
        <div className='map-container' style={{ width: '100%', height: '400px', overflow: 'hidden' }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14238.52263150537!2d80.9601149!3d26.8516973!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd93f31ca67b%3A0x922989a2283b7357!2sDr.%20Prateek%20Mehrotra%20-%20Breast%20Cancer%20Surgeon%20%7C%20Thyroid%20Surgeon%20%7C%20Endocrine%20Surgeon%20%7C%20Diabetic%20Foot%20Specialist%20Lucknow!5e0!3m2!1sen!2sin!4v1728892442437!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Map of Dr. Prateek Mehrotra"
            ></iframe>
        </div>
    );
};

export default Map;
