import React from 'react'
import Footer from '../component/Footer'
import goiter from '../img/goiter.jpg'
import para from '../img/thoriedpara.jpg'

const Thyroid = () => {
    return (
        <>
            <div className='headings'>
                <h1>Thyroid Disease & Thyroid Cancer</h1>
            </div>

            <div className='thyroid-content'>
                <p><b>Thyroid Disorders:</b> The thyroid gland, located in the neck, regulates metabolism through hormone production. Thyroid diseases include hyperthyroidism, hypothyroidism, goiter, thyroid nodules, and thyroid cancer. These disorders can have significant impacts on health, affecting energy levels, weight, and overall well-being.</p>

                <p><b>Symptoms of Thyroid Disorders:</b> Symptoms vary depending on the type of thyroid condition. Common symptoms of hyperthyroidism (overactive thyroid) include weight loss, rapid heartbeat, excessive sweating, and irritability. Hypothyroidism (underactive thyroid) can lead to fatigue, weight gain, dry skin, and depression. Goiter is characterized by an enlarged thyroid gland, and thyroid cancer may not present symptoms initially but could involve a lump in the neck, difficulty swallowing, or hoarseness.</p>

                <p><b>Thyroid Cancer:</b> Thyroid cancer is one of the most common endocrine cancers. There are several types, including papillary, follicular, medullary, and anaplastic thyroid cancer. Papillary thyroid cancer is the most common and has a high survival rate when treated early. Medullary thyroid cancer may produce elevated levels of calcitonin and is associated with genetic conditions like MEN 2 syndrome. Anaplastic thyroid cancer, though rare, is aggressive and harder to treat.</p>

                <p><b>Diagnosis:</b> Thyroid disorders are diagnosed through blood tests that measure thyroid hormone levels (T3, T4, TSH). Ultrasound imaging of the thyroid gland helps detect nodules, cysts, or signs of enlargement. In cases of suspected thyroid cancer, a fine needle aspiration biopsy may be performed to obtain a tissue sample. Radioactive iodine scans and PET scans can help assess thyroid function and locate cancer cells.</p>

                <p><b>Treatment:</b> Treatment for thyroid disorders varies depending on the specific condition. Hyperthyroidism is often treated with antithyroid medications, radioactive iodine therapy, or thyroidectomy (removal of part or all of the thyroid). Hypothyroidism is typically managed with thyroid hormone replacement therapy. Thyroid cancer treatment generally involves surgery to remove the thyroid, followed by radioactive iodine therapy to destroy remaining cancer cells. In some cases, external radiation or chemotherapy may be necessary, especially for aggressive cancers like anaplastic thyroid cancer.</p>

                <p><b>Thyroid Surgery:</b> In cases of thyroid cancer or other thyroid conditions requiring surgery, a thyroidectomy may be performed. The surgery may involve partial removal of the thyroid (lobectomy) or complete removal (total thyroidectomy). In cases of goiter, the thyroid may also be reduced in size to alleviate symptoms like difficulty swallowing or breathing. Post-surgery, patients may require lifelong hormone replacement therapy.</p>

                <img src={goiter} alt="Goiter Image" />
            </div>

            <Footer />
        </>
    )
}

export default Thyroid;
