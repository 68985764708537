import React from 'react';


const Mehrotra = () => {
  return (
    <div className="marquee">
      <p>
        Dr. Prateek Mehrotra - Breast cancer specialist | Thyroid diseases specialist | Diabetic foot Surgeon | Endocrine Surgeon
      </p>
    </div>
  );
}

export default Mehrotra;
