import React from 'react'
import CustomCarousel from './CustomCarousel'
import Section from './Section'
import Dases from './Dases'
import Services from './Services'
import Videosection from './Videosection'
import ReviewsSlider from './ReviewsSlider'
import Footer from './Footer'
import Map from './Map'
import Blog from './Blog'
import VisitorCounter from './VisitorCounter'


const Layout = () => {
  return (
    <div className='main'>
      <CustomCarousel />
      <Section />
      <Dases />
      <Services />
      <Blog />
      <Videosection />
      <ReviewsSlider />
      <VisitorCounter/>
      <Map />
      <Footer />

    </div>
  )
}

export default Layout
