import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


import throid from '../img/thiroid.jpg'
import dibfoot from '../img/dibfoot.jpg'
import paratho from '../img/para.jpg'
import adrenal from '../img/adrenal.jpg'
import indrocin from '../img/endrocin.jpg'
import breast from '../img/breastcancer.jpg'
import breastcancer from '../img/breastcancer.jpg'
import Footer from '../component/Footer';

const Myabout = () => {
    const [visibleContent, setVisibleContent] = useState(null);

    const handleClick = (content) => {
        setVisibleContent(content);
    };

    const renderContent = () => {
        switch (visibleContent) {
            case 'thyroid':
                return (
                    <Card className="content-card">
                        <Card.Body>
                            <h1> Breast Diseases  </h1>

                            <ul>
                                <li><strong>Benign Thyroid Diseases:</strong> Multinodular & Retrosternal goiters, Graves’ diseases, Toxic goiters, Autonomous functioning thyroid nodules, Thyroglossal remnants.</li>
                                <li><strong>Malignant Thyroid Diseases:</strong> Papillary, Follicular, Medullary and Anaplastic thyroid cancers, Lymphomas. Surgery for advanced thyroid cancers, including Tracheal resection, Great vein thrombectomy, Thyroidectomy, and neck dissections.</li>
                                <li><strong>Diabetic Foot Surgery:</strong> Comprehensive diabetic foot care, running a diabetic foot clinic.</li>
                                <li><strong>Breast Diseases:</strong> Breast cancers & Benign breast lesions. Breast-conserving surgery, Sentinel lymph node biopsy, Radical and Modified radical mastectomy, Skin-sparing mastectomy, Lumpectomy, Microdochectomy. Chemotherapy in breast malignancies in adjuvant, neo-adjuvant, and metastatic settings. Chemotherapy port placements.</li>
                                <li><strong>Parathyroid Diseases:</strong> Hyperparathyroidism - sporadic and in MEN settings. Minimally invasive parathyroidectomy and Radio-guided surgeries.</li>
                                <li><strong>Adrenal Diseases:</strong> Open and laparoscopic procedures for Pheochromocytoma, Cushing’s syndrome, Hyperaldosteronism, adenomas, and adreno-cortical carcinomas.</li>
                                <li><strong>Neuroendocrine Pancreatic & GIT Diseases:</strong> Carcinoid tumors, Insulinoma, Glucagonoma, Somatostatinomas, VIPoma, and PPoma’s.</li>
                            </ul>
                        </Card.Body>
                    </Card>

                );
            case 'diabetic':
                return (
                    <Card className="content-card">
                        <Card.Body>
                            <h1>ASSIGNMENTS (TILL DATE)</h1>

                            <ul>
                                <li><strong>Associate Director:</strong> Department of Breast & Endocrine Surgery, Max Super-Specialty Hospital (Erstwhile Sahara Hospital), Lucknow, India.</li>
                                <li><strong>Senior Consultant:</strong> Department of Breast & Endocrine Surgery, Sahara Multi-Specialty Hospital, Lucknow, India.</li>
                                <li><strong>M.Ch Fellow:</strong> Endocrine & Breast Surgery, Dept of Endocrine & Breast Surgery, Sanjay Gandhi Post-graduate Institute of Medical Sciences (SGPGIMS), Lucknow, India.</li>
                                <li><strong>Senior Resident in Endocrine & Breast Surgery (March 06 - June 07):</strong> Dept of Endocrine & Breast Surgery, Sanjay Gandhi Post-graduate Institute of Medical Sciences (SGPGIMS), Lucknow, India.</li>
                                <li><strong>Senior Resident in General Surgery:</strong> Deen Dayal Upadhyay Hospital, Hari Nagar, New Delhi, India.</li>
                                <li><strong>Senior Resident in General Surgery:</strong> City Hospital, Affiliated to Sir Ganga Ram Hospital, New Delhi, India.</li>
                                <li><strong>Junior Resident in General Surgery:</strong> Sir Ganga Ram Hospital, New Delhi, India.</li>
                                <li><strong>Awards:</strong> Gold Medal in Forensic Medicine and Honors in Biochemistry.</li>
                            </ul>
                        </Card.Body>
                    </Card>

                );
            case 'parathyroid':
                return (
                    <Card className="content-card">
                        <Card.Body>
                            <h1>AWARDS / FELLOWSHIPS / GRANTS</h1>

                            <ul>
                                <li><strong>International Society of Surgery Foundation (ISSF), Lupsingen, Switzerland - Travel Scholar Award 2011:</strong> Awarded at International Surgical Week ISW, Yokohama, Japan, 2011.</li>
                                <li><strong>Mumbai-Asia Pacificon Traveling Fellowship (2009-2010):</strong> Awarded by the International College of Surgeons-Indian Section.</li>
                                <li><strong>SGPGI Best M.Ch Senior Resident Award:</strong> Awarded by Sanjay Gandhi Post Graduate Institute of Medical Sciences, 2009.</li>
                                <li><strong>IAES Travel Grant:</strong> Awarded by the International Association of Endocrine Surgeons, Adelaide, Australia, 2009.</li>
                                <li><strong>Japan Surgical Society Travel Grant:</strong> For the 109th Japan Surgical Society Conference at Fukuoka, Japan, April 2009.</li>
                                <li><strong>Dr. S. Vital Best Paper Award:</strong> Endocrine Surgery sectional program during ASICON 2008 at Ludhiana, Dec 26-30, 2008. Paper: “Childhood and adolescent Pheochromocytoma: An institutional experience over two decades.”</li>
                                <li><strong>Hari Om Ashram Prerit Dr. Rangachari Research Endowment Award 2006:</strong> Awarded by Association of Surgeons of India (ASI) for research on “Breast Cancer and persistent organochlorine pesticides exposure among Indian women: A possible association.”</li>
                                <li><strong>N.K Mehra Award, 2003:</strong> Best postgraduate student in General Surgery, Sir Ganga Ram Hospital, New Delhi, during DNB training (2001-04).</li>
                                <li><strong>Shri Badri Prasad Tiwari Gold Medal, 1997:</strong> For highest marks in Forensic Medicine in MBBS.</li>
                                <li><strong>Dr. Harish Gupta Prize, 1995:</strong> Awarded by the Association of Physiologists & Pharmacologists of India (APPI) for best undergraduate paper presentation at the Annual Conference of APPI-1995. Paper: "Study of Pulmonary Functions in Swimmers of Lucknow City".</li>
                            </ul>
                        </Card.Body>
                    </Card>

                );
            case 'adrenal':
                return (
                    <Card className="content-card">
                        <Card.Body>
                            <h1>MEMBERSHIPS</h1>

                            <ul>
                                <li><strong>FAES:</strong> Fellow of Indian Association of Endocrine Surgeons of India (2024)</li>
                                <li><strong>ITS:</strong> Indian Thyroid Society (2024)</li>
                                <li><strong>ABSI:</strong> Association of Breast Surgeons of India</li>
                                <li><strong>ISTS:</strong> Indian Society of Thyroid Surgeons (2019)</li>
                                <li><strong>DFSI:</strong> Diabetic Foot Society of India (2015)</li>
                                <li><strong>ISBMR:</strong> Indian Society of Bone & Mineral Research (2011)</li>
                                <li><strong>FLCS:</strong> Fellow of Lucknow College of Surgeons (2010)</li>
                                <li><strong>IMA:</strong> Indian Medical Association - Lucknow Branch</li>
                                <li><strong>IAES:</strong> Indian Association of Endocrine Surgeons</li>
                                <li><strong>AMASI:</strong> Association of Minimal Access Surgeons of India (2007)</li>
                                <li><strong>IASO:</strong> Indian Association of Surgical Oncology (2008)</li>
                                <li><strong>RCS.Ed:</strong> Royal College of Surgeons of Edinburgh (2005)</li>
                                <li><strong>IMSA:</strong> International Medical Sciences Academy (2006)</li>
                                <li><strong>NAMS:</strong> National Academy of Medical Sciences, India (2005)</li>
                                <li><strong>ASI:</strong> Association of Surgeons of India (2002)</li>
                                <li><strong>APPI:</strong> Association of Physiologists & Pharmacologists of India (1998)</li>
                            </ul>
                        </Card.Body>
                    </Card>

                );
            case 'pancreatic':
                return (
                    <Card className="content-card">
                        <Card.Body>
                            <h1>PUBLICATIONS</h1>

                            <ul>
                                <li>Mehrotra PK, Ramchandran CS, Goel D, Arora V. “Inflammatory variant of a well-differentiated retroperitoneal liposarcoma: case report of a rare giant variety.” Ind. J Cancer. 43(1), 36-38 Mar 2006.</li>

                                <li>Mehrotra PK, Verma NS, Tiwari S, Kumar P. “Pulmonary functions in Indian sportsmen playing different sports” Ind. J Physiology Pharmacology 42(3), 412-416: July 1998.</li>

                                <li>Mehrotra PK, Yadav RK, Verma NS, Tiwari S, Shukla N "Study of pulmonary functions in swimmers of Lucknow city". Ind. J Physiology Pharmacology. 41(1), 83 - 86: Jan 1997.</li>

                                <li>Shukla A, Singh P, Shukla A, Mehrotra PK, Arshad F, Jain P. "Study of correlation of ER, PR, Her2neu receptor status in breast cancer at a single tertiary care hospital with emphasis on clinical utility of PR receptor." International Journal of Community Medicine & Public Health. 2018 Nov; 5(11): 4853-4857.</li>

                                <li>Mishra A, Mehrotra PK, Agarwal G, Agarwal A, Mishra SK. "Pediatric and adolescent pheochromocytoma: clinical presentation and outcome of surgery." Indian Pediatr. 2014 Apr;51(4): 299-302.</li>

                                <li>Anand M, Singh J, Siddiqui MKJ, Taneja A, Patel DK, Mehrotra PK. "Organo-chlorine pesticides in the females suffering from Breast Cancer and its Relation to Estrogen Receptor Status." Drug Metab Toxicol April 2013 4:(4). JMDT, an open access journal.</li>

                                <li>Mehrotra PK, Mishra A, Mishra SK, Agarwal G, Agarwal A, Verma AK. "Medullary Thyroid Cancer: Clinico-pathological Profile and Outcome in a Tertiary Care Center in North India." World J Surg. 2011 Jun; 35(6); 1273 - 80.</li>

                                <li>Mishra A, Chandra R, Mehrotra PK, Bajpai P, Agrawal D. "Glutathione S-transferase M1 and T1 polymorphism and response to neoadjuvant chemotherapy (CAF) in breast cancer patients." Surg Today. 2011 Apr; 41(4): 471-6. Epub 2011 Mar.</li>

                                <li>Mehrotra PK, Ramachandran CS, Arora V. "Two port laparoscopic ventral hernia mesh repair: an innovative technical advancement." Int J Surg. 2011; 9(1): 79-82. Epub 2010 Oct 8.</li>

                                <li>Agarwal A*, Mehrotra PK*, Jain M, Gupta SK, Mishra A, Chand G, Agarwal G, Verma AK, Mishra SK, Singh U. "Size of the Tumor and Pheochromocytoma of the Adrenal Gland Scaled Score (PASS): Can They Predict Malignancy?" World J Surg. 2010 Dec; 34(12): 3022-8.</li>

                                <li>Mehrotra PK, A Mishra. "Response to External beam radiotherapy in patients with advanced or recurrent non anaplastic thyroid cancer: Memorial Sloan Kettering cancer center experience." Int J Radiat Oncol Biol Phys. 2010 Jan 1; 76(1):314; author reply 314.</li>

                                <li>M Ahamed, PK Mehrotra, P. Kumar, MKJ Siddiqui. “Placental Lead Induced oxidative stress and preterm delivery.” Environmental Toxicology and Pharmacology. 2009. 27(1): 70-74.</li>

                                <li>NK Kumar, PK Mehrotra, A Agarwal, AK Verma. "Hypocalcaemia & parathyroid hormone assay following total thyroidectomy: predicting the future." World J Surg. 2007 Aug; 31(8):1718-9.</li>

                                <li>PK Mehrotra, A Agarwal, A Mishra, G Agarwal, AK Verma, SK Mishra. "Comments on: Technetium 99m-sestamibi subtraction scintigraphy vs. ultrasonography combined with a rapid parathyroid hormone assay in parathyroid aspirates in preoperative localization of parathyroid adenoma and in directing surgical approach." Clin Endocrinol 2007. 66: 899-900.</li>

                                <li>Siddiqui MK, Jyoti, Singh S, Mehrotra PK, Singh K, Sarangi R. "Comparison of some trace elements concentration in blood, tumour free breast and tumour tissues of women with benign and malignant breast lesion: An India study." Environ Int. 2006 Jul; 32(5): 630-7.</li>

                                <li>Mehrotra PK, Ramchandran CS, Gupta L. "Gallstone presenting as obstructive gangrenous appendicitis: Laparoscopic management." Journal of Laparo-endoscopic and Advanced Surgical Techniques. 2005 Dec; 15(6): 627-9.</li>

                                <li>Mehrotra PK, Ramchandran CS, Goel D, Arora V. "Giant pseudocyst of the anterior abdominal wall following mesh repair of incisional hernia: a rare complication managed laparoscopically." Hernia. 2005 Sep 1; 1-3.</li>

                                <li>Siddiqui MKJ, Anand M, Mehrotra PK, Sarangi R, Mathur N. "Biomonitoring of organo-chlorines in women with benign and malignant breast disease." J of Environmental Research. 2005 Jun; 98(2): 250-7.</li>

                                <li>Siddiqui MKJ, Srivastava S, Srivastava SP, Mehrotra PK, Mathur N, Tandon I. "Persistent chlorinated pesticides and intra-uterine foetal growth retardation: a possible association." Archives of Occupational & Environmental Health 2003. (76): 75-80.</li>

                                <li>Siddiqui MKJ, Mehrotra PK, Srivastava S. "Environmental exposure of lead as a risk for prostate cancer." Biomedical & Environmental Science. 2002 Dec 15 (4): 298 - 305.</li>

                                <li>Srivastava S, Mehrotra PK, Srivastava SP, Siddiqui MKJ. "Some essential elements in maternal and cord blood in relation to birth weight and gestation age of the baby." Journal of Biological Trace Element Research. 2002 May: 86 (2): 97 - 105.</li>

                                <li>Srivastava S, Mehrotra PK, Srivastava SP, Tandon I, Siddiqui MKJ. "Blood lead and zinc in pregnant women and their offspring in Intra Uterine Growth retarded cases." Journal of Analytical Toxicology 2001 Sept 25 (6): 461-465.</li>
                            </ul>
                        </Card.Body>
                    </Card>

                );

            case 'Breast':
                return (
                    <Card className="content-card">
                        <Card.Body>
                            <h1>BOOK CHAPTERS / CONTRIBUTION IN PROCEEDINGS</h1>
                            1. “Pancreatic Neuro-endocrine Tumours.” SK Mishra, Prateek K Mehrotra. Text Book Chapter for the 2nd Edition of the “ASI Text Book of Surgery”  <br/> <br/>

                            2. “What’s new in management of Goiter” Prateek Mehrotra, S.K Mishra. (Chapter in Proceedings) 53rd Foundation day and 5th Surgical Education Program. Department of Surgery. CSM Medical University Lucknow. 16th-17th Jan 2008.
                        </Card.Body>
                    </Card>
                );

            case 'Breastprolem':
                return (
                    <Card className="content-card">
    <Card.Body>
        <h1>Conference presentations</h1>
        <ul>
            <li>1. “Hypocalcemia following Thyroidectomy” & Panel Discussion on Complications of Thyroidectomy. 25th Indian Association of Endocrine Surgeons of India (IAESCON 2024), Lucknow, 19-21 Sept 2024.</li>
            <li>2. “Panel Discussion on Spectrum of Benign Breast Diseases” in 12th Annual Conference of Association of Breast Surgeons of India (ABSICON 2024), Lucknow, 2–4 Aug 2024.</li>
            <li>3. “Antimicrobial prophylaxis in different organ system surgeries” State CME on anti-microbial stewardship, organized by Lucknow chapter of Hospital Infection Society, India, 9 Mar 2019.</li>
            <li>4. “Lingual Thyroid” 4th Annual Meeting of Indian Society of Thyroid Surgeons (ISTS), SGPGI Lucknow, 15–17 Feb 2019.</li>
            <li>5. “Metaplastic Squamous Cell Carcinoma of the Breast: Does it really have a poor outcome? Review of two cases.” World Congress of Surgery (WCS-2017), The International Society of Surgery ISS/SIC, Basel, Switzerland, 13–17 Aug 2017.</li>
            {/* Add the remaining items in similar fashion */}
            <li>6. “Micro papillary carcinoma Thyroid - To Observe or to Intervene (Debate)” at the 17th Annual Conference of Indian Association of Endocrine Surgeons, Subharti Medical College, Meerut, 17–18 Sept 2016.</li>
            <li>...{/* Continue with the rest of the items in the same format for a clean and readable layout */}</li>
        </ul>
    </Card.Body>
</Card>
                );

           




            default:
                return null;
        }



    };

    return (
        <>

            <div className='servid'>
                <h1> About US </h1>
                <h2>Click Button To View </h2>
            </div>

            <div className="services-container">
                <Button variant="primary" onClick={() => handleClick('thyroid')}>
                Breast work
                </Button>
                <Button variant="primary" onClick={() => handleClick('diabetic')}>
                    ASSIGNMENTS
                </Button>
                <Button variant="primary" onClick={() => handleClick('parathyroid')}>
                    AWARDS
                </Button>
                <Button variant="primary" onClick={() => handleClick('adrenal')}>
                    MEMBERSHIPS
                </Button>
                <Button variant="primary" onClick={() => handleClick('pancreatic')}>
                    PUBLICATIONS
                </Button>

                <Button variant="primary" onClick={() => handleClick('Breast')}>
                    BOOK CHAPTERS
                </Button>
                <Button variant="primary" onClick={() => handleClick('Breastprolem')}>
                Conference Presentarions
                </Button>

               

                <div className="mt-3">{renderContent()}</div>
            </div>


            <Footer />
        </>
    );
}

export default Myabout;
