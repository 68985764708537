import React from 'react';
import Footer from '../component/Footer';
import foot from '../img/doctor.jpg'; // Importing breast cancer image

const Cancer = () => {
    return (
        <>
            <div className="headings">
                <h1>Breast Cancer </h1>
            </div>
            
            <div className="parathyroid-content">
              
                <img 
                    src={foot} 
                    alt="Parathyroid illustration" 
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }} 
                />
            </div>

            <div className="breast-cancer-content">
               

                <h2>Be Aware of Breast Cancer: Early Detection Saves Lives!</h2>
                <p>
                    Breast cancer is the most common cancer affecting women across the world, including India.
                    Worldwide, female breast cancer has now surpassed lung cancer as the most commonly diagnosed
                    cancer. Nearly one new case of breast cancer is being diagnosed every four minutes worldwide.
                </p>
                <p>
                    According to research, 1 in every 28 women is likely to get affected by the disease during their lifetime.
                    While breast cancer occurs almost entirely in women, around 1-2% men are likely to get affected too.
                </p>
                <p>
                    Due to the rising incidence, mortality rates, and advanced stages at diagnosis, spreading awareness 
                    about breast cancer is essential for early detection and favorable outcomes.
                </p>

                <h3>Risk Factors</h3>
                <p>Breast cancer risk factors include:</p>
                <ul>
                    <li>Family history of breast or ovarian cancer (first-degree relative).</li>
                    <li>Personal history of breast cancer.</li>
                    <li>Ashkenazi Jewish ancestry.</li>
                    <li>Modifiable factors like obesity, smoking, and alcohol consumption.</li>
                </ul>
                <p>Only 7-10% of breast cancers are hereditary. The majority can be prevented through lifestyle changes.</p>

                <h3>Warning Signs</h3>
                <p>Consult a specialist if you notice:</p>
                <ul>
                    <li>A lump in the breast or underarm.</li>
                    <li>Changes in size, shape, or appearance of the breast.</li>
                    <li>An inverted or retracted nipple.</li>
                    <li>Peeling, scaling, or crusting around the nipple or breast skin.</li>
                    <li>Bloody or clear discharge from the nipple.</li>
                </ul>
                <p>
                    Note: Pain is not usually a symptom in the initial stages. A painless lump is more concerning.
                </p>

                <h3>Prevention and Early Diagnosis</h3>
                <p>
                    Prevention includes a healthy lifestyle—low-fat diets, regular exercise, and avoiding tobacco and alcohol.
                </p>
                <p>Early detection methods:</p>
                <ul>
                    <li>Monthly Breast Self-Examination (BSE).</li>
                    <li>Clinical examination by a specialist.</li>
                    <li>Annual mammography (begin at age 40).</li>
                </ul>

                <h3>How to Perform a Breast Self-Examination (BSE)</h3>
                <p>Steps for a proper self-exam:</p>
                <ol>
                    <li>Lie down and place your right hand behind your head.</li>
                    <li>Use the middle fingers of your left hand to check the right breast with small circular motions.</li>
                    <li>Sit or stand and feel your armpit (breast tissue extends here).</li>
                    <li>Check for nipple discharge by gently squeezing the nipple.</li>
                </ol>
                <p>If you notice any changes, consult a specialist promptly.</p>

                <h3>Diagnosis and Treatment</h3>
                <p>
                    Diagnosis involves clinical examination, imaging (mammography/ultrasound), and biopsy if needed.
                </p>
                <p>
                    Treatment options include surgery, chemotherapy, radiation, and targeted therapy. Early-stage cancers 
                    may be treated with breast-conserving surgery, ensuring better outcomes.
                </p>

                <h3>Myths About Breast Cancer</h3>
                <ul>
                    <li><b>Myth:</b> Cancer spreads due to biopsy or surgery.<br /><b>Reality:</b> These are safe diagnostic tools.</li>
                    <li><b>Myth:</b> Mammograms cause cancer.<br /><b>Reality:</b> Radiation exposure is minimal and safe.</li>
                    <li><b>Myth:</b> Only older women are affected.<br /><b>Reality:</b> Breast cancer can affect women in their 30s or younger.</li>
                </ul>

                <p className="conclusion">
                    Awareness is key to saving lives. Regular screenings and a healthy lifestyle are vital in the fight against breast cancer.
                </p>
            </div>

            <Footer />
        </>
    );
};

export default Cancer;
