import React from 'react';
import Footer from '../component/Footer';
import indo from '../img/endocrine.jpg'; // Importing the endocrine image

const Endocrine = () => {
    return (
        <>
            <div className="headings">
                <h1>Understanding Pancreatic Neuroendocrine Tumors (PNETs)</h1>
            </div>

            <div className="endocrine-content">
                <p>
                    Pancreatic neuroendocrine tumors (PNETs), also known as islet cell tumors, are rare but significant types of cancer originating from the hormone-producing cells of the pancreas. 
                    They account for about 7% of all pancreatic malignancies and differ from the more common pancreatic ductal adenocarcinoma. 
                    Understanding their characteristics, symptoms, and treatment options is vital for awareness and early intervention.
                </p>
                
                <h2>Categories of PNETs</h2>
                <ul>
                    <li><strong>Functioning Tumors:</strong> Produce excess hormones, leading to specific syndromes. Examples include:
                        <ul>
                            <li><strong>Insulinomas:</strong> Cause hypoglycemia due to excessive insulin production.</li>
                            <li><strong>Gastrinomas:</strong> Lead to severe peptic ulcers from excess gastrin.</li>
                            <li><strong>VIPomas:</strong> Result in watery diarrhea and electrolyte imbalances.</li>
                            <li><strong>Glucagonomas:</strong> Cause glucose intolerance and skin rashes.</li>
                        </ul>
                    </li>
                    <li><strong>Non-Functioning Tumors:</strong> Do not produce excess hormones and often present later with symptoms like abdominal pain or weight loss.</li>
                </ul>

                <h2>Symptoms and Diagnosis</h2>
                <p>
                    Symptoms can range from abdominal pain and weight loss to more specific signs like jaundice in advanced cases. Diagnosing PNETs involves imaging studies (CT scans, MRI) and 
                    biochemical tests to assess hormone levels, with a biopsy often required for confirmation.
                </p>

                <h2>Treatment Options</h2>
                <p>
                    Managing PNETs is complex and may involve:
                    <ul>
                        <li><strong>Surgery:</strong> The primary treatment for localized tumors.</li>
                        <li><strong>Locoregional Therapies:</strong> Such as radiofrequency ablation or chemoembolization for non-surgical candidates.</li>
                        <li><strong>Systemic Therapies:</strong> Targeted treatments like everolimus or peptide receptor radionuclide therapy (PRRT) for advanced cases.</li>
                    </ul>
                </p>

                <h2>Conclusion</h2>
                <p>
                    Pancreatic neuroendocrine tumors are challenging yet manageable with early detection and appropriate treatment. Awareness among healthcare providers and the public is critical for improving outcomes. 
                    If you experience concerning symptoms or have a family history of related syndromes, consult a healthcare professional for evaluation.
                </p>

                <img 
                    src={indo} 
                    alt="Endocrine Pancreatic Illustration" 
                    style={{ width: '100%', height: 'auto', marginTop: '20px' }} 
                />
            </div>

            <Footer />
        </>
    );
};

export default Endocrine;
