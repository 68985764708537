import React from 'react';
import Footer from '../component/Footer';
import adrenal from '../img/adrenal.jpg'; // Importing adrenal image


const AdrenalTumours = () => {
    return (
        <>
            <div className="adrenal-container">
                <div className="headings">
                    <h1>Adrenal Tumours</h1>
                </div>

                <div className="adrenal-tumours-content">
                    <section>
                        <h2>Introduction</h2>
                        <p>
                            Adrenal tumours are abnormal growths in the adrenal glands, located above the kidneys. These tumours
                            can be either benign (non-cancerous) or malignant (cancerous). The adrenal glands produce important 
                            hormones that regulate metabolism, immune response, and stress responses. Some adrenal tumours can 
                            cause excessive hormone production, leading to conditions such as Cushing’s syndrome or 
                            hyperaldosteronism. Proper diagnosis and treatment are crucial for managing adrenal tumours, and 
                            surgical removal is often the recommended option in certain cases.
                        </p>
                    </section>

                    <section>
                        <h2>Symptoms</h2>
                        <p>
                            Symptoms vary based on whether the tumour produces hormones. Common symptoms include weight gain, 
                            high blood pressure, and rapid heartbeat. Large tumours may cause discomfort or pain in the abdomen.
                        </p>
                    </section>

                    <section>
                        <h2>Diagnosis</h2>
                        <p>
                            Diagnosis typically involves imaging tests like CT or MRI scans and hormonal assessments via blood 
                            or urine tests. A thorough physical examination can also help identify related symptoms.
                        </p>
                    </section>

                    <section>
                        <h2>Treatment Options</h2>
                        <p>
                            Treatment strategies depend on the type of tumour. Surgical removal is often recommended for 
                            functional or malignant tumours. Medications may be used to manage symptoms if surgery isn't 
                            feasible. Advanced cases may require radiation or chemotherapy.
                        </p>
                    </section>

                    <img 
                        src={adrenal} 
                        alt="Adrenal Tumours Illustration" 
                        className="adrenal-image" 
                    />
                </div>
            </div>

            <Footer />
        </>
    );
};

export default AdrenalTumours;
