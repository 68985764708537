import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, set, update } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyANWcFlBZz-k85182nThJVtVwMw2TPjmVI",
    authDomain: "counter-project-4f818.firebaseapp.com",
    databaseURL: "https://counter-project-4f818-default-rtdb.firebaseio.com",
    projectId: "counter-project-4f818",
    storageBucket: "counter-project-4f818.firebasestorage.app",
    messagingSenderId: "297754506106",
    appId: "1:297754506106:web:0e3b345e67369c23745cc3",
    measurementId: "G-SM7Q80GMVM"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, ref, get, set, update };
